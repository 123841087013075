





































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import StartViewModel from '@/vue-app/view-models/components/contract-savings/start-view-model';

const SERVICES = {
  allianz: 'ContractSavingsStartAllianz',
  kuspit: 'ContractSavingsStartKuspit',
};

@Component({
  name: 'ContractSavingsStart',
  components: {
    ContractSavingsStartAllianz: () => import('./start/ContractSavingsStartAllianz.vue'),
    ContractSavingsStartKuspit: () => import('./start/ContractSavingsStartKuspit.vue'),
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsStart extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  start_view_model = Vue.observable(new StartViewModel());

  current_component = SERVICES[this.service];

  @Watch('start_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  created() {
    this.start_view_model.small_screen = getScreenSizeVariant() === 'small';
    this.$emit('loadingInfo', this.start_view_model.is_loading);
  }

  async nextStep() {
    const access_to_personal_info = this.$refs.personal_info_form as any;
    const saved = await access_to_personal_info.nextStep();
    if (saved) {
      this.$emit('nextStep');
    }
  }
}

